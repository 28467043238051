import React from "react"
import Log from "./../../../templates/log.js"
import { graphql } from "gatsby"
import Img from "gatsby-image"

import { Caption, FirstP, Emoji } from "./../../../components/helpers.js"

export default ({ data, pageContext }) => {

    const content = <>

        <FirstP>Recently we bought some new plants to fill up some of the space after we moved to a larger house. I am not the best plant owner, and need to be reminded when to water these new housemates.
            Roughly at the same time, I found a cool new component, that can switch from red to green, and only requires current when switching. This makes it a great status indicator for battery-powered
            products. One plus one is two, and this gave me the idea to build a soil moisuture sensor using this status indicator.
        </FirstP>
                       
        <p>
            For this product I wanted to have the following features:
        </p>

        <ul>
            <li>WiFi connectivity to log data to the cloud</li>
            <li>Visible status indicator on the PCB</li>
            <li>A button to allow for user inputs, like setting the baseline moisture level</li>
            <li>Low power, battery powered. Preferrably it should work for at least a year</li>
            <li>As small as possible given the other requirements</li>
        </ul>
            
        <p>
            In the next sections I will discuss the status indicator, why I selected the ESP32, the battery selection and the sensing method.
        </p>

        <h3>Status indicator</h3>

        <p>As I mentioned, I found a cool status indicator, made by the company Alfa-Zeta. The unique thing about this component is that it only requires current to switch the color, but
            it does not require anything to hold the current color which makes it perfect for battery-powered applications.
            The idea will be to switch the color to red if a plant needs more water <Emoji e="😎" />.
        </p>

        <Img fadeIn={false} fluid={data.img1.childImageSharp.fluid} alt="Status indicator" />
        <Caption>The status indicator can switch between red and green.</Caption>    

        <p>The way this component works is by two small coils that flip a magnetic element up side down. According to the datasheet, the minimum voltage for this is 4.5V, but I found it even 
            works on a single AA battery, which will come in handy as we will see later. That being said, the current during switching is higher than what can be provided from a GPIO pin. Therefore
            a H-bridge is needed to control this indicator from the microcontroller. 
        </p>

        <h3>ESP8266 vs ESP32</h3>

        <p>
            My first idea was to use ESP8266, since it is of course powerful enough for this simple application. This is the first project where I want to run on batteries, and therefore also the deep 
            sleep capabilities are important. I quickly found out that the ESP8266 is too limited in this area. While deep sleep is supported, it is not possible to clearly distinghuish between the following
            three cases:
        </p>

        <ol>
            <li>Power on</li>
            <li>Wake from deep sleep through timer</li>
            <li>Wake from deep sleep through button</li>
        </ol>

        <p>Especially the last case is problematic. While it is possible to distinghuish in your code between the first two cases, the button press can't be separated. This brings some issues for the code 
            because it is not straightforward to respond to a button press in a different way than to a normal wakeup event. This is why I chose the ESP32 which does not have this issue <Emoji e="👍" />.</p>

        <h3>Battery selection</h3>

        <p>
            Since this was the first time designing something on batteries I considered four different options. 
        </p>

        <Img fadeIn={false} fluid={data.img2.childImageSharp.fluid} alt="Battery options" />
        <Caption>The four options I considered for the battery, with the correct relative scaling: CR2032, CR2, LifePo4 AAA and LiPo cell.</Caption>    

        <h4>AAA LifePo4 cell (200MaH)</h4>
        <p>LifePo4 is a great chemistry for electronics projects. A fully charged cell is less than 3.6V and it stays above 3V for most of its capacity. This means you can use it with an ESP32
            without the need for a voltage regulator. Drawbacks are the low power density and high price.</p>

        <h4>1s LiPo cell (400MaH)</h4>
        <p>LiPo cells are a very common battery because of their high power density. For me this option had two drawbacks. First of all the max cell voltage is 4.2V, meaning that a voltage regulator 
            is a must. Secondly, because of the shape it is not possible to mount the battery directly to the board using SMD components.</p>

        <h4>CR2 battery (800MaH)</h4>
        <p>
            This battery has a high power density, and a form factor that fits wel into my design. The main drawback is that these batteries are not rechargable, and that the voltage is below 3V for 
            most of its life, which is too low for the ESP32. However, there is exactly one ESP32 module, the ESP32-WROVER, which uses 1.8V flash memory instead of 3V. 
            Because of this, this module works for voltages all the way down to 2.3V, and a CR2 battery can be used without a voltage regulator.   
        </p>

        <h4>CR2032 coin cell (220MaH)</h4>
        <p>A coin cell would be awesome because of its size. The voltage is the same as for the CR2 battery. The main problem of the CR2032 is that the current needed for an ESP32 can't be provided.
            A workaround could be to add a capacitor that buffers enough energy to keep the device alive for a few seconds. However, this makes it awkward for debugging and development. Maybe
            it is something I will revisit in the future.</p>

        <h3>Battery life</h3>
        <p>Considering these options, I have decided to use the CR2 battery in this product. By making some assumptions in the calculation below, the extimated battery life is around 2 years. I am sure
            that my calculations are too optimistic, but even so, I hope that one year of battery life should be within reach.
        </p>

        <Img fadeIn={false} fluid={data.img3.childImageSharp.fluid} alt="Excel calculation for battery life" />
        <Caption>Excel calculation for battery life.</Caption>    
                
        <h3>Sensing method</h3>

        <p>The sensor will use a large metal pad in the soil to do a capacitive measurement. This concept is used in other commercial sensors as well, such as the chirp, or the Adafruit Stemma. 
            There are differences in the design of the actual pad though. The Stema does not have any shielding around or behind the pad, while the Chirp has both. For my design I decided to place 
            a ground plane around the sensor, to shield it from disturbances, but no ground on the bottom layer in order to keep the sensitivity as high as possible.
        </p>            
            
        <p>After deciding to use the ESP32, I realised that it actually has touch GPIO inputs. Touch inputs are
            exactly that, a capacitive measurement on a metal pad. After some searching on Google I found that others have succesfully used this functionality for soil measurements as well, so that
            makes the board a lot simpler, since no additional sensor circuitry is needed.</p>
        
        <h3>Design and schematic</h3>

        <p>The final design and schematic for the PCB can be seen below.</p>

        <Img fadeIn={false} fluid={data.img5.childImageSharp.fluid} alt="PCB Design" />
        <Caption>PCB design. The status indicator and battery will be mounted on the back of the board.</Caption> 
        <Img fadeIn={false} fluid={data.img6.childImageSharp.fluid} alt="Schematic" />
        <Caption>Schematic.</Caption> 
        
        <p>
            In the next post I aim to test and calibrate the sensor with some real water, soil and plants!
        </p>
        

    </>;

    return (<Log pageContext={pageContext}>{content}</Log>);
}

export const query = graphql`
{
    img1: file(relativePath: { eq: "indicator.jpg" }) {
        childImageSharp {
            fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid_withWebp
            }
        }
    }

    img2: file(relativePath: { eq: "battery.jpg" }) {
        childImageSharp {
            fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid_withWebp
            }
        }
    }

    img3: file(relativePath: { eq: "excel.png" }) {
        childImageSharp {
            fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid_withWebp
            }
        }
    }

    img5: file(relativePath: { eq: "sensor.jpg" }) {
        childImageSharp {
            fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid_withWebp
            }
        }
    }

    img6: file(relativePath: { eq: "plantschematic.png" }) {
        childImageSharp {
            fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
}
`